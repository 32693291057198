<script setup lang="ts">
import { isAppUsing } from '@@/bits/flip'
import { __ } from '@@/bits/intl'
import { availableLocales } from '@@/bits/locale'
import { OzBoxColors } from '@@/library/v4/components/OzBox.vue'
import OzIconButton, {
  OzIconButtonColorScheme,
  OzIconButtonSizePreset,
} from '@@/library/v4/components/OzIconButton.vue'
import type { OzMenuRowsRow } from '@@/library/v4/components/OzMenuRows.vue'
import OzMenuRows, {
  OzMenuRowsFocusStyle,
  OzMenuRowsRowRole,
  OzMenuRowsSizePreset,
} from '@@/library/v4/components/OzMenuRows.vue'
import OzScreenSizeAwarePopoverModal from '@@/library/v4/components/OzScreenSizeAwarePopoverModal.vue'
import type { PopoverAnchor } from '@@/types'
import { computed } from 'vue'

const props = withDefaults(
  defineProps<{
    windowHeight: number
    boxClasses?: string[]
    currentLocale?: string
    anchor?: Partial<PopoverAnchor>
    width?: number
    isPhone?: boolean
    preferredTrayStartHeight?: number
    xBackButton?: boolean
    xCloseButton?: boolean
    xHeader?: boolean
  }>(),
  {
    boxClasses: () => [],
    currentLocale: 'en',
    anchor: () => ({}),
    width: 304,
    isPhone: true,
    preferredTrayStartHeight: undefined,
    xBackButton: true,
    xCloseButton: false,
    xHeader: true,
  },
)

defineEmits<{
  (name: 'cancel'): void
  (name: 'anchor-update'): void
  (name: 'back'): void
  (name: 'close'): void
  (name: 'change', eventPayload: string): void
}>()

const rowData = computed<OzMenuRowsRow[]>(() => {
  return availableLocales
    .filter((locale) => {
      return locale.standard !== 'qy_GB' || isAppUsing('yodishLanguage')
    })
    .map((locale) => {
      const data = {
        rightIcon: props.currentLocale === locale.html ? 'checkmark_thicker' : '',
        rightIconHighlighted: true,
        role: OzMenuRowsRowRole.MenuItemRadio,
        ariaChecked: String(props.currentLocale === locale.html),
        eventPayload: locale.html,
        ref: locale.html,
        testId: `${locale.standard}LanguageMenuRow`,
      }
      return locale.standard === 'qs_GB'
        ? { ...data, htmlText: `<span class="font-[cursive] italic">${locale.name}</span>` }
        : { ...data, text: locale.name }
    })
})
</script>

<template>
  <OzScreenSizeAwarePopoverModal
    role="dialog"
    :aria-label="__('Language selector')"
    :is-phone="isPhone"
    :is-draggable-drawer="true"
    :x-popover-tip="false"
    :popover-width="width"
    :popover-anchor="anchor"
    :color="OzBoxColors.PrimaryBlur"
    :box-classes="[...boxClasses, 'overflow-hidden']"
    :preferred-tray-start-height="preferredTrayStartHeight"
    :window-height="windowHeight"
    :disable-drag="true"
    @scrim-click="$emit('cancel')"
    @scrim-esc="$emit('cancel')"
    @trigger-anchor-update="$emit('anchor-update')"
  >
    <template v-if="xHeader" #header>
      <div
        :class="[
          'relative',
          // Layout
          'flex items-center justify-center',
          // Spacing
          isPhone ? 'py-3' : 'pt-2 pb-4',
          isPhone ? 'mt-1' : 'mt-2',
        ]"
      >
        <OzIconButton
          v-if="isPhone && xBackButton"
          :color-scheme="OzIconButtonColorScheme.Secondary"
          :size-preset="OzIconButtonSizePreset.H24px"
          :label="__('Go back')"
          icon-name="chevron_left_center"
          class="absolute start-4 px-1"
          @click="$emit('back')"
        />

        <OzIconButton
          v-else-if="xCloseButton"
          :color-scheme="OzIconButtonColorScheme.Secondary"
          :size-preset="OzIconButtonSizePreset.H24px"
          :label="__('Close')"
          icon-name="close"
          class="absolute start-4 px-1"
          @click="$emit('close')"
        />

        <span
          :class="[
            isPhone ? 'text-heading-3' : 'text-heading-4',
            'font-semibold',
            'text-dark-text-100',
            'dark:text-light-text-100',
          ]"
          >{{ __('Choose language') }}</span
        >
      </div>
    </template>
    <template #body>
      <div :class="['overflow-auto scrollbar-thin dark:scrollbar-thin-dark']">
        <OzMenuRows
          :accessibility-role="'menu'"
          :aria-label="__('Language')"
          :is-phone="isPhone"
          :active-row="currentLocale"
          :focus-style="OzMenuRowsFocusStyle.Bubble100"
          :size-preset="isPhone ? OzMenuRowsSizePreset.H48px : OzMenuRowsSizePreset.H44px"
          :rows="rowData"
          @select-row="(_event, eventPayload) => $emit('change', eventPayload)"
        />
      </div>
    </template>
  </OzScreenSizeAwarePopoverModal>
</template>
